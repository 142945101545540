.app-login {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
	padding: 32px;

	.app-login-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 32px;
		width: 100%;
		max-width: 1084px;

		@include media ('<xl') {
			flex-direction: column;
		}

		.app-login-form {

			@include media ('>=xl') {
				flex: 0 0 384px;
				max-width: 384px;
			}

			.app-login-logo {
				margin-bottom: 12px;
			}

			.app-login-heading {
				@include typography('h4');
				margin-bottom: 12px;
			}

			.app-login-text {
				margin-bottom: 24px;
			}

			.app-login-wrap-login {
				margin-bottom: 12px;
			}

			.app-login-wrap-password {
				position: relative;

				.app-login-password-toggle {
					background-image: url('../images/icons/icon-eye.svg');
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					display: flex;
					position: absolute;
					bottom: 13px;
					right: 10px;
					width: 20px;
					height: 20px;

					&.active {
						background-image: url('../images/icons/icon-eye-close.svg');
					}
				}
			}

			.app-login-input-login,
			.app-login-input-password {
				background-position: center left 10px;
				background-repeat: no-repeat;
				padding-left: 40px;
				padding-right: 40px;
			}

			.app-login-input-login {
				background-image: url('../images/icons/icon-user.svg');
			}

			.app-login-input-password {
				background-image: url('../images/icons/icon-pass.svg');
			}

			.app-login-btn {
				border-radius: 100px;
				margin-top: 24px;
				width: 100%;

				span {
					display: inline-flex;
					align-items: center;
					gap: 8px;
					font-size: 16px;
					font-weight: 400;
					position: relative;

					&::after {
						border-right: 1px solid $white;
						border-bottom: 1px solid $white;
						content: '';
						width: 6px;
						height: 6px;
						transform: rotate(-45deg);
					}
				}
			}
		}

		.app-login-image {

			@include media ('>=xl') {
				flex: 0 0 624px;
				max-width: 624px;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
}