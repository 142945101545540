.app-nav {
	background-color: $gray-1;
	display: flex;
	flex-direction: column;
	flex: 0 0 272px;
	max-width: 272px;
	padding: 32px 20px;
	position: relative;
	z-index: 10;

	.app-nav-wrapper {
		margin-bottom: 32px;

		.app-nav-label {
			color: $gray-2;
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 12px;
		}

		.app-nav-menu {
			display: flex;
			flex-direction: column;
			gap: 2px;

			> li {
				display: flex;

				> a {
					border-radius: 6px;
					border: 1px solid transparent;
					color: $gray-2;
					display: flex;
					align-items: center;
					gap: 12px;
					font-size: 14px;
					padding: 12px;
					transition: $trans-fast;
					width: 100%;

					@include media('<2xl') {
						padding: 6px 12px;
					}

					&:hover,
					&.active,
					&.ancestor-active {
						background-color: $white;
						border: 1px solid $gray-400;

						img {
							opacity: 1;
						}
					}

					img {
						opacity: .68;
						transition: $trans-fast;
					}
				}

				.submenu {
					box-shadow: 100px 4px 100px 0px #00000010;
					background-color: $white;
					display: none;
					flex-direction: column;
					gap: 16px;
					padding: 124px 20px 20px;
					min-height: 100vh;
					width: 180px;
					position: absolute;
					top: 0;
					left: 100%;

					@include media('<2xl') {
						gap: 2px;
					}

					&.active {
						display: flex;
					}

					li {
						display: flex;

						&.has-submenu {

							> a {
								position: relative;

								&::after {
									border-bottom: 2px solid $gray-3;
									border-right: 2px solid $gray-3;
									content: '';
									width: 8px;
									height: 8px;
									position: absolute;
									top: 50%;
									right: 16px;
									transform: translateY(-50%) rotate(-45deg);
								}
							}
						}

						a {
							border-radius: 8px;
							color: $gray-3;
							display: flex;
							font-size: 14px;
							padding: 10px 16px;
							transition: $trans-fast;
							width: 100%;

							@include media('<2xl') {
								padding: 6px 16px;
							}

							&:hover,
							&.active,
							&.ancestor-active {
								background-color: $gray-4;
							}
						}
					}
				}
			}
		}

		&.app-nav-logo {
			display: flex;
			justify-content: center;
			margin-bottom: 28px;
		}

		&.app-nav-tools {
			margin: auto 0 0;
		}
	}
}