.alert {
	border: none!important;
}

.accordion-button {
	color: $gray-3;
	font-size: 24px;
	font-weight: 500;

	&:focus {
		box-shadow: none;
	}

	&:not(.collapsed) {
		background-color: $gray-1;
		color: $gray-3;
	}
}