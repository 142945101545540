@use 'sass:map';
@use 'sass:list';
@use 'sass:string';

@use './utils' as *;

///
/// List of media expressions, that can be used in `media` mixin
///
$media-expressions: (
	"screen": "screen",
	"print": "print",
	"motionOK": "(prefers-reduced-motion: no-preference)",
	"motionNotOK": "(prefers-reduced-motion: reduce)",
	"opacityOK": "(prefers-reduced-transparency: no-preference)",
	"opacityNotOK": "(prefers-reduced-transparency: reduce)",
	"useDataOK": "(prefers-reduced-data: no-preference)",
	"useDataNotOK": "(prefers-reduced-data: reduce)",
	"OSdark": "(prefers-color-scheme: dark)",
	"OSlight": "(prefers-color-scheme: light)",
	"highContrast": "(prefers-contrast: more)",
	"lowContrast": "(prefers-contrast: less)",
	"portrait": "(orientation: portrait)",
	"landscape": "(orientation: landscape)",
	"HDcolor": "(dynamic-range: high)",
	"touch": "(hover: none) and (pointer: coarse)",
	"stylus": "(hover: none) and (pointer: fine)",
	"pointer": "(hover) and (pointer: coarse)",
	"mouse": "(hover) and (pointer: fine)",
	"retina2x":
	"(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)",
	"retina3x":
	"(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi), (min-resolution: 3dppx)",
) !default;

///
/// Media parsing engine
/// Inspired by [Kitty Giraudel](https://github.com/KittyGiraudel) parsing engine used in [include-media](https://github.com/eduardoboucas/include-media/)
///
/// The main difference is that we are not parsing expressions into oldschool `(max-width: 767px)` media queries, rather using modern range syntax `(width < 768px)` letting PostCSS plugin do this job (unless this syntax is stable)
///

///
/// Get all operators of an expression
///
/// @param {string} $expression - Expression to extract operator from
///
/// @return {list} - List of operators: `>=`, `>`, `<=`, `<`, `≥`, `≤`
///
@function get-expression-operators($expression) {
	$operators: ();

	@each $operator in ('>=', '>', '<=', '<', '≥', '≤') {
		@if string.index($expression, $operator) {
			$operators: list.append($operators, $operator);
		}
	}

	@if list.length($operators) > 0 {
		@return $operators;
	} @else {
		@return false;
	}
}

///
/// @param {String} $expression
///
/// @return {String|false}
///
@function has-expression-dimension($expression) {
	$dimensions: 'width', 'height';

	@each $dimension in $dimensions {
		@if string.index($expression, $dimension) {
			@return $dimension;
		}
	}

	@return false;
}

@function parse-expression($expression) {
	@if $expression == false {
		@return false;
	}

	// If its media expression - use it instead of parsing whole expression
	@if map.has-key($media-expressions, $expression) {
		@return map.get($media-expressions, $expression);
	}

	// If there are no operators, assume that user know what they're doing and just return expression as it is
	$operators-list: get-expression-operators($expression);

	@if $operators-list == false {
		@return $expression;
	}

	// Get all operators in the expression and add spaces around them
	@each $operator in $operators-list {
		$expression: str-replace($expression, $operator, ' #{$operator} ');
		$expression: str-replace($expression, ' =', '=');
		$expression: str-replace($expression, '  ', ' ');
		$expression: str-trim($expression);
	}

	// Set default dimension if there is none
	$has-dimension: has-expression-dimension($expression);

	@if $has-dimension == false {
		$expression: 'width #{$expression}';
	}

	// Replace breakpoint keys with actual values from the theme
	$splitted-expression: string.split($expression, ' ');

	@for $index from 1 through (length($splitted-expression)) {
		$element: list.nth($splitted-expression, $index);

		@if map.has-key($breakpoints, $element) {
			$value: map.get($breakpoints, $element);
			$splitted-expression: list.set-nth($splitted-expression, $index, $value);
		}
	}

	// Convert list into string again, add parentheses
	$expression: list-implode($splitted-expression, ' ');
	$expression: '(#{$expression})';

	@return $expression;
}

///
/// Mixin to simplify and speed up styling with media queries
/// Heavily inspired by [include-media](https://github.com/eduardoboucas/include-media) but with few additional tricks and improvements
///
/// ⚠ Warning!
///
/// This mixin produces media queries in new media-range syntax. To make sure that it will work across all browsers - please make sure that you're using a [PostCSS Preset ENV plugin](https://www.npmjs.com/package/postcss-preset-env) with correct setup
///
/// It accepts a lot of different type of expressions, here are some examples:
/// * `> lg`, `>lg`, `<=1024px`, `>=50em` - an operator combined with the value or breakpoint name (defined in `$breakpoints` variable)
/// * `width < lg`, `768px <= width < xl`, `height > xs` - dimension (`width` used by [default] or `height`) combined with value or breakpoint
/// * `motionOK`, `screen`, `retina2x` - name of media-expression, defined in `media-expressions` variable
/// * `false` - if false is provided, it will be skipped and no media query will be used. It's usefull while generating classnames with breakpoints, where we have to generate basic, default class without `@media`
/// * any other expression - if mixin doesn't recognize your expression (it **MUSTN'T** have an operator), it will print it as it is
///
/// Example:
///     ```scss
///
///      @include media(
///         '>lg',
///         '>= lg',
///         'width < lg',
///         'width <= 1024px',
///         '768px > width > lg',
///         'height > xs',
///         'motionOK',
///         '(prefers-reduced-data: no-preference)'
///       ) {
///         visibility: hidden;
///       }
///       // so it looks like this:
///       @include media('>=md') {}
///       // mixin is producing query with range syntax
///       @media (width > 768px) {}
///       // now PostCSS plugin creates polyfill as a final result
///       @media (min-width: 768px) {}
///
///     ```
///
///
/// @param {string} $conditions... - multiple string arguments
/// @return {void} - returns provided content wrapped (or not) with media queries
///
@mixin media($conditions...) {
	@if (list.length($conditions) == 0) {
		@content;
	} @else if (list.length($conditions) > 0) {
		$parsed-expression: parse-expression(list.nth($conditions, 1));

		@if $parsed-expression == false {
			@content;
		} @else {
			@media #{string.unquote($parsed-expression)} {
				$sliced-conditions: list-slice($conditions, 2);

				@include media($sliced-conditions...) {
					@content;
				}
			}
		}
	}
}
