* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	--font-family-sans: 'Inter';
}

html {

}

body {
	background-color: $white;
	color: $text-color;
	font-family: var(--font-family-sans), font-family-fallback-sans();
	@include typography("base");
}
