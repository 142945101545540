// Create darker background
@mixin opacityBackground($color: black, $opacity: .25) {
	position: relative;
	opacity: 1;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: $opacity;
		background-color: $color;
	}
}

// Set icon as a background (mostly for pseudoelments, such as ::before/::after)
@mixin setBackgroundIcon($url) {
	background: url($url) center / contain no-repeat;
}

@mixin contrast {
	body.contrast & {
		@content;
	}
}
