.btn-primary {
	background-color: $color-3!important;
	color: $white!important;

	&:hover,
	&.active {
		background-color: $color-31!important;
	}

	&.active {

		&[disabled] {
			background-color: $color-3!important;
		}
	}

	&[disabled] {
		background-color: $gray-5!important;
		border-color: $gray-5!important;
	}
}

.btn-outline-secondary {
	border-color: $gray-6;
	color: $gray-3!important;

	&:hover,
	&.active {
		background-color: $gray-1!important;
	}

	&.active {
		border-color: $gray-6;

		&[disabled] {
			background-color: $white!important;
			border-color: $white;
			box-shadow: 0px -2px 0px 0px #0000000D inset;
		}
	}

	&[disabled] {
		background-color: $white!important;
	}
}

.btn-link {
	color: $gray-3!important;
	text-decoration: none;

	&:hover,
	&.active {
		background-color: $gray-1!important;
	}

	&:hover {
		text-decoration: underline;
	}

	&.active {

		&[disabled] {
			background-color: $gray-1!important;
			border-color: transparent!important;
		}
	}

	&[disabled] {
		background-color: $white!important;
		border-color: $gray-6;
	}
}