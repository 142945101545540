@use 'sass:list';
@use './utils' as *;

@mixin prose($selectors...) {
	$parsed-selectors: ();
	@each $selector in $selectors {
		$parsed-selectors: list.append($parsed-selectors, if(type-of($selector) == 'string', unquote(str-replace($selector, '&', ".#{$prose-class}")), $selector));
	}

	// TODO: After getting rid of bootstrap, replace `:is()` with `:where()`
	.#{$prose-class} :is(#{$parsed-selectors}):not(:where([class ~="not-#{$prose-class}"], [class ~="not-#{$prose-class}"] *)) {
		@content;
	}
}
