.form-check-input:disabled {
	background-color: rgba($black, .25);
}

.form-label,
.form-check-label {
	color: $gray-3;
	font-size: 14px;
	font-weight: 500;
}

.form-control {
	border-color: $gray-6;
	padding: .625rem .75rem;

	&[disabled] {
		background-color: $gray-1;

		&[readonly] {
			background-color: $gray-1;
		}
	}
}

.form-hint {
	color: $gray-7;
	font-size: .875em;
	margin-top: .25rem;
	width: 100%;
}

.ui-selectmenu-button {
	background-color: $white;
	border-color: $gray-6;
	border-radius: 10px;
	color: $gray-7;
	padding: 12px 40px 12px 12px;
	position: relative;
	transition: $trans-fast;
	width: 100%!important;

	&::after {
		content: '';
		border-right: 1px solid $gray-7;
		border-bottom: 1px solid $gray-7;
		margin-top: -2px;
		width: 8px;
		height: 8px;
		position: absolute;
		top: 50%;
		right: 14px;
		transform: translateY(-50%) rotate(45deg);
		transition: $trans-fast;
	}

	&.ui-button {

		&:active {
			border-color: $color-31;
			background-color: $color-31;

			&::after {
				border-color: $white;
			}
		}
	}

	&.ui-widget {
		font-family: revert;
	}

	&.ui-selectmenu-button-closed {

	}

	&.ui-selectmenu-button-open {

		&::after {
			margin-top: 2px;
			transform: translateY(-50%) rotate(-135deg);
		}
	}
}

.ui-selectmenu-menu {
	box-shadow: 0px 4px 6px -2px #0D0D1208;
	box-shadow: 0px 12px 16px -4px #0D0D1214;

	.ui-menu {
		border-color: $gray-6;
		border-radius: 10px;
		padding: 8px;

		.ui-menu-item-wrapper {
			border-radius: 8px;
			padding: 8px 40px 8px 12px;
		}
	}

	.ui-widget {
		font-family: revert;
	}

	.ui-widget-content {
		border-color: $gray-6;
		color: $gray-8;

		.ui-state-active {
			background-color: $gray-1;
			border-color: $gray-1;
			color: $gray-8;
			position: relative;

			&::after {
				border-left: 2px solid $gray-7;
				border-bottom: 2px solid $gray-7;
				content: '';
				margin-top: -2px;
				width: 12px;
				height: 6px;
				position: absolute;
				top: 50%;
				right: 14px;
				transform: translateY(-50%) rotate(-45deg);
			}
		}
	}
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {

}