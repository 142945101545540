button {
	background: none;
	border: none;
	margin: 0;
	padding: 0;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

p, ol, ul, blockquote {
	margin: 0;
}

h1,h2,h3,h4,h5,h6 {
	font-size: inherit;
	line-height: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	text-wrap: balance;
}

ul, ol {
	padding-left: 0;
	list-style: none;
}

a {
	&, &:hover {
		color: inherit;
		text-decoration: none;
	}
}

h1 { @include typography('h1'); }
h2 { @include typography('h2'); }
h3 { @include typography('h3'); }
h4 { @include typography('h4'); }
h5 { @include typography('h5'); }
h6 { @include typography('h6'); }

h1, h2, h3, h4, h5, h6 {
	margin-bottom: .5em;
}

p {

	& + p {
		margin-top: 16px;
	}
}

input[type=text],
input[type=number] {
	min-width: 100px;
}

textarea {
	min-width: 200px;
}