.container {
	--container-max-width: 1140rpx;
	--container-margin: 16rpx;
	padding-inline: 0;
	margin-inline: auto;
	width: 100%;
	max-width: min(
		100% - (2 * var(--container-margin, 0rpx)),
		var(--container-max-width, 100%)
	);
}

.container.container-full {
	--container-max-width: 100%;
	--container-margin: 16rpx;
}

.container .container:not(.nested-with-margin) {
	--container-margin: 0rpx;
}
