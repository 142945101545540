@use 'sass:map';
@use './utils' as *;

///
/// Renders map or key/value list into css properties
/// @param {list} $properties-map
/// @param {string} $key
///
@mixin parse-properties($style) {
	@each $property, $value in $style {
		@if (type-of($value) == 'map') {
			$expression: $property;
			@if (type-of($property) == 'number') {
				$expression: 'width >= #{$property}px';
			}

			@include media($expression) {
				@include parse-properties($value);
			}
		} @else {
			#{$property}: #{$value};
		}
	}
}

///
/// Convert non-map entries to font-size value
///
$_temp-typography: $typography;
@each $size, $val in $typography {
	@if type-of($val) != 'map' {
		$_temp-typography: map.deep-merge(
				$_temp-typography,
				(
					'#{$size}': (
						'font-size': $val,
					),
				)
		);
	}
}

$typography: $_temp-typography;

@mixin typography($size) {
	@if map.has-key($typography, $size) {
		$style: map.get($typography, $size);
		@include parse-properties($style);
	} @else {
		@warn "Unknown size: #{$size}. Available sizes: #{map.keys($typography)}.";
	}
}
