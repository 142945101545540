/* COLORS */
$contrast: yellow;
$contrast-05: yellow;
$white: #ffffff;
$black: #000000;

$gray-50: #E6E6E6;
$gray-100: #F5F5F5;
$gray-200: #CCCCCC;
$gray-300: #B3B3B3;
$gray-400: #999999;
$gray-500: #7F7F7F;
$gray-600: #666666;
$gray-700: #4D4D4D;
$gray-800: #333333;
$gray-900: #1A1A1A;

$gray-1: #fafafa;
$gray-2: #525252;
$gray-3: #0a0a0a;
$gray-4: #f1f1f1;
$gray-5: #cacaca;
$gray-6: #d4d4d4;
$gray-7: #737373;
$gray-8: #262626;

$color-1: #C1FFCF;
$color-2: #6ADE66;
$color-3: #29C370;
$color-31: #31972E;

$color-4: #1E1E1E;
$color-5: #414140;
$color-6: #1C4456;
$color-7: #C7E6F4;

$red: red;
$blue: blue;

$light-blue: #C7E6F4;

$brand: $color-2;
$brand-dark: darken($brand, 8%);
$text-color: $black;

/* TRANSITIONS */
$trans-fast: all .25s ease-in-out;
$trans-medium: all .5s ease-in-out;
$trans-slow: all .75s ease-in-out;

/* MEDIA */
$breakpoints: (
	"xs": 375px,
	"sm": 576px,
	"md": 768px,
	"lg": 992px,
	"xl": 1200px,
	"2xl": 1366px,
	"3xl": 1440px,
) !default;

/* TYPOGRAPHY */
/* Line-heights */
$leading-none: 1;
$leading-compact: 1.15;
$leading-tight: 1.25;
$leading-snug: 1.375;
$leading-normal: 1.5;
$leading-relaxed: 1.625;
$leading-smooth: 1.75;
$leading-loose: 2;

$typography: (
	// Body
		"2xs": ("font-size": 10rpx, "line-height": $leading-normal),
		"xs": ("font-size": 12rpx, "line-height": $leading-normal),
		"sm": ("font-size": 14rpx, "line-height": $leading-normal),
		"base": ("font-size": 16rpx, "line-height": $leading-normal),
		"lg": ("font-size": 18rpx, "line-height": $leading-normal),
		"xl": ("font-size": 20rpx, "line-height": $leading-normal),
		"2xl": ("font-size": 24rpx, "line-height": $leading-normal),
		"3xl": ("font-size": 30rpx, "line-height": $leading-normal),
		"4xl": ("font-size": 36rpx, "line-height": $leading-normal),
		"5xl": ("font-size": 48rpx, "line-height": $leading-tight),
		"6xl": ("font-size": 60rpx, "line-height": $leading-tight),
		"7xl": ("font-size": 72rpx, "line-height": $leading-tight),
		"8xl": ("font-size": 96rpx, "line-height": $leading-compact),
		"9xl": ("font-size": 128rpx, "line-height": $leading-compact),
	// Headings
		'h1': (
				'font-size': 52px,
				'line-height': $leading-tight,
				'font-weight': 500,
		),
		'h2' : (
				'font-size': 48px,
				'line-height': $leading-tight,
				'font-weight': 500,
		),
		'h3' : (
				'font-size': 40px,
				'line-height': $leading-tight,
				'font-weight': 500,
		),
		'h4' : (
				'font-size': 36px,
				'line-height': $leading-tight,
				'font-weight': 500,
		),
		'h5': (
				'font-size': 24px,
				'line-height': $leading-snug,
				'font-weight': 500,
		),
		'h6': (
				'font-size': 20px,
				'line-height': $leading-snug,
				'font-weight': 500,
		),
) !default;

/* PROSE */
$prose-class: prose !default;