.table-container {
	box-shadow: inset -50px 0px 60px -80px rgba(0, 0, 0, .5);
	position: relative;
	overflow-x: auto;

	table {
		color: #272835;
		font-size: 14px;

		thead {
			border-bottom: 1px solid $gray-300;

			th {
				font-weight: 500;
			}
		}

		tbody {

			tr {
				border-bottom: 1px solid $gray-100;
			}
		}

		th, td {
			padding: 12px;
			white-space: nowrap;

			&:first-child {
				background-color: $white;
				position: sticky;
				left: 0;
				z-index: 2;

				&::after {
					background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
					content: '';
					pointer-events: none;
					width: 46px;
					height: 100%;
					position: absolute;
					top: 0;
					left: 100%;
				}
			}
		}
	}
}
