.font-family-sans {
	//noinspection CssNoGenericFontName
	font-family: font-family-fallback("sans");
}

.font-family-serif {
	//noinspection CssNoGenericFontName
	font-family: font-family-fallback("serif");
}

.font-family-mono {
	//noinspection CssNoGenericFontName
	font-family: font-family-fallback("mono");
}

@each $size, $value in $typography {
	.text-#{$size} {
		@include typography($size);
	}
}
