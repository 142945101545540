.#{$prose-class} {
	--prose-body: #{$text-color};
	--prose-base-margin-top: 24rpx;
	--prose-paragraph-margin-top: 1.25em;
	--prose-strong-weight: 600;
	--prose-not-margin-top: var(--prose-base-margin-top);

	--prose-lead: inherit;
	--prose-lead-font-size: 1.25em;
	--prose-lead-margin-top: var(--prose-base-margin-top);

	--prose-links: #{$text-color};
	--prose-links-underline: currentColor;

	--prose-bullets: #{$gray-400};
	--prose-counters: #{$gray-600};
	--prose-list-margin-left: 24rpx;
	--prose-list-margin-top: var(--prose-base-margin-top);
	--prose-list-item-margin-top: 8rpx;

	--prose-hr: #{$gray-300};
	--prose-hr-margin-y: 16rpx;

	--prose-headings: #{$text-color};
	--prose-headings-margin-top: 32rpx;
	--prose-headings-margin-bottom: 16rpx;

	--prose-img-aligned-padding: 1em;
	--prose-img-margin-top: var(--prose-base-margin-top);

	--prose-video-margin-top: var(--prose-base-margin-top);

	--prose-blockquote-margin-top: var(--prose-base-margin-top);

	@include typography('base');
	color: var(--prose-body);

	@include media('>=lg') {
		--prose-hr-margin-y: 32rpx;
	}

	.not-#{$prose-class} {
		margin-top: var(--prose-not-margin-top);
	}

	@include contrast {
		--prose-body: #{$contrast};
		--prose-links: #{$contrast};
		--prose-bullets: #{$contrast};
		--prose-counters: #{$contrast};
		--prose-hr: #{$contrast};
		--prose-headings: #{$contrast};
	}
}

@include prose(p) {
	margin-top: var(--prose-paragraph-margin-top);
}

@include prose("[class~=\"lead\"]") {
	color: var(--prose-lead);
	font-size: var(--prose-lead-font-size);
	font-weight: inherit; // TODO: remove this line if you get rid of bootstrap
}

@include prose("a, a:hover") {
	color: var(--prose-links);
	text-decoration: underline;
	text-decoration-skip-ink: none;
	text-underline-offset: 4rpx;
	text-decoration-color: var(--prose-links-underline);
}

@include prose(strong) {
	font-weight: var(--prose-strong-weight);
}

@include prose("ol,ul") {
	margin-top: var(--prose-list-margin-top);
	margin-left: var(--prose-list-margin-left);
}

@include prose(ol) {
	list-style-type: decimal;
}

@include prose(ul) {
	list-style-type: disc;
}

@include prose(li) {
	margin-top: var(--prose-list-item-margin-top);
}

@include prose("ol > li") {
	&::marker {
		color: var(--prose-counters, currentColor);
		font-weight: 400;
	}
}

@include prose("ul > li") {
	&::marker {
		color: var(--prose-bullets, currentColor);
	}
}

@include prose(ol ol) {
	list-style: lower-latin;
}

@include prose(ol ol ol) {
	list-style: lower-roman;
}

@include prose(hr) {
	border-top: 1px solid var(--prose-hr);
	margin-top: var(--prose-hr-margin-y);
	margin-bottom: var(--prose-hr-margin-y);
}

@include prose(blockquote) {
	margin-top: var(--prose-blockquote-margin-top);
}

@include prose("h2,h3,h4,h5,h6") {
	color: var(--prose-headings);
	margin-top: var(--prose-headings-margin-top);
	margin-bottom: var(--prose-headings-margin-bottom);
}

@include prose(h2) {
	@include typography('h2');
}

@include prose(h3) {
	@include typography('h3');
}

@include prose(h4) {
	@include typography('h4');
}

@include prose(h5) {
	@include typography('h5');
}

@include prose(h6) {
	@include typography('h6');
}

@include prose("h2 + *, h3 + *, h4 + *, h5 + *, h6 + *") {
	margin-top: 0;
}

@include prose(img) {
	margin-top: var(--prose-img-margin-top);
}

@include prose("img.alignleft") {
	float: left;
	padding: var(--prose-img-aligned-padding) var(--prose-img-aligned-padding) var(--prose-img-aligned-padding) 0;
	margin: 0;
}

@include prose("img.aligncenter") {
	clear: both;
	padding: var(--prose-img-aligned-padding) 0;
	margin: 0;
}

@include prose("img.alignright") {
	float: right;
	padding: var(--prose-img-aligned-padding) 0 var(--prose-img-aligned-padding) var(--prose-img-aligned-padding);
	margin: 0;
}

@include prose(video) {
	margin-top: var(--prose-video-margin-top);
}

@include prose(":first-child") {
	margin-top: 0
}
